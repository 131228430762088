import React from "react";
import Moment from "react-moment";
import "./Volvia.css";
import { useStore } from "../../Store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const EventsComponentVolvia = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();

  const caseEvents = [];
  const caseEventsETA = [];
  const caseEvents2 = [];
  const caseEvents2ETA = [];

  if (casesStore.events != null) {
    casesStore.events.forEach((item) => {
      if (item.eventType === 1) {
        caseEvents.push(
          <li key={"eventContainer" + item.eventType} className="status-container-event-volvia">
            <div className="status-container-event-volvia-1 ">
              <div>{t(`statuscontainerevent${item.eventType}`)}</div>
              <Moment className="status-container-event-volvia-time" format="HH.mm">{item.eventDateTime}</Moment>
            </div>
          </li>
        )
        caseEventsETA.push(
          <div className="status-container-event-volvia-header">
            {t("statuseventcontainerheader")}
          </div>
        )
      } else {
        caseEvents2.push( 
          <li key={"eventContainer" + item.eventType} className="status-container-event-volvia">
            <div className="status-container-event-volvia-1">{t(`statuscontainerevent${item.eventType}`)}
              <Moment className="status-container-event-volvia-time" format="HH.mm">{item.eventDateTime}</Moment>
            </div>
          </li>
        )
        if (caseEvents2ETA.length === 0) {
          caseEvents2ETA.push(
            <div className="status-container-event-volvia-header">
              {t("statuscontainer1")}
            </div>
          )
        } else {
          caseEvents2ETA.fill(
            <div className="status-container-event-volvia-header">
              {t("statuscontainer1")}
            </div>
          )
        }
      }
    });

    return (
      <div id="status-container-polestar">
        {(() => {
          return (
            <div>
              {caseEvents2ETA}
              <ul className="volvia-events-list">
                {caseEvents2.reverse()}
              </ul>
              {caseEventsETA}
              <ul className="volvia-events-list">
                {caseEvents.reverse()}
              </ul>
            </div>
          )
        })()}
      </div>
    );
  }

  return <div className="loading-bar"></div>;
}

export default observer(EventsComponentVolvia);