import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { useStore } from "../../Store";

const EventsComponent = () => {
  const { casesStore } = useStore();
  const { t } = useTranslation();
  const sProductId = casesStore.conceptId;

  const caseEvents = [];
  if (casesStore.events != null) {
    casesStore.events.forEach((item) =>
      caseEvents.push(
        <div
          key={"eventContainer" + item.eventType}
          className="status-container-event"
        >
          <div
            key={"eventType" + item.eventType}
            className={`status-container-event-logo e-${item.eventType}`}
          ></div>
          <div className="status-container-event-text-block row justify--space-between">
            <div className={`status-container-event-name product-font-${sProductId}`}>
              {t(`statuscontainerevent${item.eventType}`)}
            </div>
            <div className={`status-container-event-time product-font-${sProductId} justify--self-end`}>
              <Moment format="HH:mm">{item.eventDateTime}</Moment>
            </div>
          </div>
        </div>
      )
    );

    return (
      <div id="status-container">
        <div className={`status-container-header product-font-${sProductId}`}>
          {t("EventsTitle")}
        </div>
        {caseEvents}
      </div>
    );
  }

  return <div className="loading-bar"></div>;
};
export default observer(EventsComponent);